@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");

body {
    font-family: "Roboto", sans-serif;
    background-color: #f5f5f5;
    padding-top: 3.75rem !important;
}

*,
a {
    outline: none;
    text-decoration: none;
    box-sizing: border-box;
}
body,
html {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
}

::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #ffffff;
}

::-webkit-scrollbar-thumb {
    background-color: #9696a0;
    border-radius: 0.25rem;
    // outline: 1px solid slategrey;
}

.mui-dropdown {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            font-size: 14px;
            line-height: 1;
            padding: 12px 20px;
            white-space: nowrap;
            font-weight: 400;
            transition: all 0.5s ease 0s;
            color: #212121;
            &:hover,
            &:active,
            &:focus {
                background-color: #e5efff;
                color: #678ecf;
            }
        }
    }
}

.MuiList-root {
    margin: 0;
    padding: 0 !important;
    li.mui-dropdown-list-item {
        font-size: 14px;
        line-height: 1;
        padding: 12px 20px;
        white-space: nowrap;
        font-weight: 400;
        transition: all 0.5s ease 0s;
        color: #212121;
        &:hover,
        &:active,
        &:focus {
            background-color: #e5efff;
            color: #678ecf;
        }
    }
}

.MuiTooltip-popper {
    &.MuiTooltip-popperArrow {
        .MuiTooltip-arrow {
            width: 1.5em;
            height: 1.21em;
            margin-bottom: -1.02em !important;
            &::before {
                background-color: #fff;
            }
        }
        &.comment-cell-tooltip {
            .MuiTooltip-arrow {
                transform: translate(20px, 0) !important;
            }
            .MuiTooltip-tooltip {
                max-width: 500px;
            }

            @media (max-width: 568px) {
                .MuiTooltip-tooltip {
                    max-width: 90%;
                    margin-left: 5%;
                }
            }
        }
    }
}

.m-slide-menu {
    header {
        background-color: #f5f5f5;
        border-bottom: 1px solid #f4f4f4;
        padding: 15px;
        width: 100%;

        .menu-avatar {
            display: flex;
            width: 100%;
            justify-content: center;
        }

        .avatar-name,
        .avatar-email {
            display: flex;
            width: 100%;
            justify-content: center;
            padding: 5px 0;
        }
        .avatar-name {
            font-size: 1.25rem !important;
            font-weight: 300;
        }
        .avatar-email {
            font-size: 0.875rem;
        }
    }
    footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid #f4f4f4;
        background-color: #f5f5f5;
    }
}

.faq-modal {
    background-color: #fff !important;
    max-width: calc(100% - 40px);
    .control-arrow {
        top: auto !important;
        bottom: 15px !important;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        border: 1px solid #2979ff !important;
        color: #2979ff;
        opacity: 1 !important;
        padding: 0 !important;
        background-color: #fff !important;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 0 !important;
    }
    .control-next {
        right: calc(50% - 35px) !important;
        &::before {
            border: none !important;
            background: url("./assets/right-arrow.svg");
            background-repeat: no-repeat;
            width: 7px;
            height: 13px;
            margin: 0 !important;
        }
    }
    .control-prev {
        left: calc(50% - 35px) !important;
        &::before {
            border: none !important;
            background: url("./assets/left-arrow.svg");
            background-repeat: no-repeat;
            width: 7px;
            height: 13px;
            margin: 0 !important;
            padding-right: 2px;
        }
    }
    .control-arrow.control-disabled {
        opacity: 0.5 !important;
        display: inline-block !important;
        font-size: 0 !important;
    }
    .thumbs-wrapper {
        display: none;
    }

    @media screen and (max-width: 767px) {
        h5 {
            font-size: 22px;
            line-height: 1.75;
        }
    }
}

@media (max-width: 767px) {
    .mob-none {
        display: none;
    }
}

@media (max-width: 640px) {
    body {
        padding-top: 50px !important;
    }
}

@media (min-width: 768px) {
    .md-none {
        display: none;
    }
}

@media (max-width: 380px) {
    .msearchWrap .mob-block {
        a {
            font-size: 13px;
        }
    }
}
@media (max-width: 340px) {
    .share-modal {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}
